import React from "react"
import ProjetosFinalistas2021 from '@/components/ProjetosFinalistas2021'

const Finalistas = ({ title }) => {
  return (
    <section className="pageBanner" style={{ background: '#FAFAFA' }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="banner_content">
              <h2 style={{ color: '#026FC2', textAlign: `center` }}>{title}</h2>
            </div>
          </div>
        </div>
        <div className="row" style={{ textAlign: `center`, marginTop: 32 }}>
          <div className="col-lg-12">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe6c2ERPWPrzkJJKS9NkpWvSLkJdni9tyZLKMpCPYyb-rcXsg/viewform?embedded=true" width="100%" height="1100" frameborder="0" marginheight="0" marginwidth="0"/>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Finalistas;